.group-list__item {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.group-list__info {
  h3 {
    color: #f06a0f;
  }
}
