@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

.create-items-page {
  font-family: $font-family-other;
  min-height: calc(100vh - 180px);
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .btn--main:disabled {
    background-color: gray;
    border-color: gray;
    color: white
  }

  .form-control:disabled {
    background-color: #e9ecef;
  }

  .row-top,
  .row-bot {
    width: 100%;
  }
  .upload-image-txt {
    padding-top: 30px;
  }
  .c-form {
    margin-top: 0 !important;
    .upload-image {
      box-shadow: none !important;
    }
  }
  .add-more-image {
    margin-bottom: 0;
    font-size: 80%;
    text-decoration: underline;
    color: #f06a0f !important;
  }
  .add-more-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .list-image {
    display: flex;
    flex-wrap: wrap;
    gap: 18.75px;
    margin: 20px 0;
    .upload-image {
      width: 107px;
      height: 107px;
      padding: 1px;
      position: relative;
      border: 1px dashed #ccc;
      .main-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .cancel-btn {
        width: 22px;
        height: 22px;
        position: absolute;
        top: 2px;
        right: 2px;
        cursor: pointer;
      }
    }
  }
  .upload-image-square {
    border: 1px dashed #ccc;
    img {
      z-index: 99;
    }
    .upload-image {
      border-radius: 0 !important;
    }
    .inputFile {
      z-index: 999;
      width: '100%';
      height: '100%';
      opacity: 0;
      padding-left: '100px';
      margin-right: '-100px';
      cursor: 'pointer';
    }
  }
  .sub-item {
    margin-bottom: 10px;
    &-info {
      display: flex;
      align-items: baseline;
      .form-group {
        width: 100% !important;
      }
      .form-control-line {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 0;
        padding-left: 10px;
      }
    }
  }
  input {
    &:focus {
      box-shadow: none !important;
    }
  }
  .detail-list-news {
    font-size: 1rem;
    &__item {
      margin-bottom: 10px;
      &--background {
        background-color: #ecfbfb;
        padding: 10px;
        z-index: 3;
        position: relative;
        padding-right: 50px;
        .c-form {
          color: #179d9a;
          label {
            margin: 0;
          }

          .form-control {
            border: 1px solid #179d9a;
            color: #179d9a;
          }
          .row {
            & ~ .row {
              margin-top: 10px;
            }
            .col-3 {
              align-self: center;
              margin-bottom: 0;
            }
            &.row-price {
              a {
                color: #179d9a;
                display: inline-block;
              }
              .col-10 {
                padding-right: 130px;
                position: relative;
                @include media-breakpoint-down(sm) {
                  padding-right: 120px;
                }
              }
              .btn-action {
                position: absolute;
                right: 8px;
                top: 9px;
              }
            }
          }
        }
        .arrow-drap {
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          transform: translateY(-50%);
          right: 20px;
          cursor: pointer;
          &:hover {
            filter: brightness(0.8);
          }
          &img {
            max-width: inherit;
            width: 40px;
            max-height: 40px;
          }
          &-up {
            top: 50%;
            background-image: url('../../../assets/images/up-arrow.png');
          }
          &-down {
            top: 60%;
            background-image: url('../../../assets/images/down-arrow.png');
          }
        }
        &.-gray {
          background-color: rgb(134 131 132 / 5%);
          padding-top: 30px;
          padding-bottom: 30px;
          .c-form {
            filter: grayscale(100%);
          }
        }
      }
    }
    .required-label {
      color: #333;
    }
  }
  a {
    cursor: pointer;
  }
  .is-error {
    border: 1px solid rgb(255, 64, 64) !important;
  }
  .is-error-sub-item {
    border-bottom: 1px solid rgb(255, 64, 64) !important;
  }
  @include media-breakpoint-down(sm) {
    .detail-list-news__item,
    .sub-item {
      font-size: 12px;
    }
    .list-image {
      gap: 10px;
    }
  }
  .invalid-feedback {
    display: block !important;
  }
}
.product-setting {
  font-family: $font-family-other;
  img {
    z-index: 99;
  }

  small.text-warning {
    span.c-txt-org {
      font-size: inherit;
    }
  }

  .btn--org {
    background-color: $orange;
    border-color: $orange;
    &:focus {
      background-color: darken($orange, 10%);
      color: white;
      border-color: darken($orange, 10%);
    }
    &:disabled {
      background-color: gray;
      color: white;
      border-color: gray;
    }
    &:hover{
      background-color: darken($orange, 5%);
      color: white;
      border-color: darken($orange, 5%);
    }
  }

  .text-available-stock {
    display: block;
    font-size: 11px;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  .upload-image-square {
    border: 1px dashed #ccc;
    .upload-image {
      box-shadow: none !important;
      border-radius: 0 !important;
    }
    .inputFile {
      z-index: 999;
      width: '100%';
      height: '100%';
      opacity: 0;
      padding-left: '100px';
      margin-right: '-100px';
      cursor: 'pointer';
    }
  }
  input {
    &:focus {
      box-shadow: none !important;
      border: 1px solid #ced4da !important;
    }
  }
  .is-error {
    border-color: rgb(255, 64, 64) !important;
    &:focus {
      box-shadow: none !important;
      border: 1px solid rgb(255, 64, 64) !important;
    }
  }
  .is-error-sub-item {
    border-bottom: 1px solid rgb(255, 64, 64) !important;
  }
  .invalid-feedback {
    display: block !important;
    font-size: 11px;
  }
}
.button-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  &:after {
    display: inline-block;
    content: '\00d7';
    color: $gray-600;
    font-size: 1.75rem;
    line-height: 0;
    padding: 5px 5px;
  }
}

@media screen and (min-width: 768px) {
  .detail-list-news__item,
  input::placeholder {
    font-size: 16px;
  }

  .detail-list-news__item {
    img {
      width: 80px;
    }
  }
}

@media screen and (max-width: 768px) {
  .detail-list-news__item {
    img {
      width: 60px;
    }
  }
}
