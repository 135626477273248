.profile-page {
  padding-bottom: 1rem;

  .c-form.c-form--profile {
    margin-top: -50px;
    margin-bottom: 20px;
  }

  .c-form .upload-image {
    width: 107px;
  }
}

.action-link {
  display: flex;
  padding: 0;
  list-style: none;
  width: 100%;
  justify-content: center;
  margin: 8px 0;
}

.logout-btn {
  border: 2px solid #f6660c;
  border-radius: 30px;
  margin: 12px 0;
  cursor: pointer;

  &:hover {
    background-color: #f6660c;

    li {
      color: white;
    }
  }
}

.action-link li {
  font-size: 14px;
  color: #f6660c;
  margin-right: 15px;
  font-weight: 600;
  cursor: pointer;
}

.switch-version {
  > p {
    text-align: center;
    color: #f6b20c;
    font-size: 0.6rem;
    margin-bottom: 6px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.upgrade-premium {
  display: flex;
  justify-content: center;
  width: 100%;

  > p {
    font-size: 0.8rem;
    color: #fd7e14;
    text-decoration: underline;
    cursor: pointer;
  }
}

.user-invoice {
  scroll-behavior: smooth;
  font-size: 0.8rem;

  .box-filter {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    .wrap-pagination {
      .btn-prev,
      .btn-next {
        background-color: #fff;
        padding: 8px 12px;
        border: 1px solid #ced4da;
        color: orange;
        font-weight: 500;
        width: auto;

        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: orange;
          color: #fff;
        }
      }
    }

    .select-filter {
      display: flex;

      select {
        font-size: 14px;
        outline: none;
        color: orange;
        border-color: gray;
      }
    }
  }

  .invoice-header {
    color: #15cdca;
    padding: 10px 0;
  }

  .invoice-item {
    // padding: 10px 0px;
    line-height: 2;
    // border-top: 1px solid #dee2e6;
    // border-left: 1px solid #dee2e6;
    // border-right: 1px solid #dee2e6;
    // border-bottom: 1px solid #dee2e6;

    // &:last-child {

    // }
    .cancel {
      cursor: pointer;
    }

    .payment {
      cursor: pointer;
    }

    .text-direct {
      color: #15cdca !important;
    }

    p {
      margin-bottom: 0;
    }

    // .btn-confirm {
    //   background-color: #f6660c;
    //   color: white;
    // }
    // button {
    //   font-size: 12px;
    // }
    .btn-primary {
      color: white !important;

      &:hover {
        background-color: darken(#0badad, 10%) !important;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.modal-cancel {
  .modal-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .modal-header {
      .modal-title {
        text-align: left !important;
      }
    }
  }

  .btn-no {
    border: 1px;
    outline: none;
    // border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 3px;
  }

  .btn-yes {
    outline: none;
    border: none;
    padding: 0.375rem 1rem;
    border-radius: 3px;
  }
}

.swal2-popup {
  width: 25em !important;
}

.swal2-icon {
  font-size: 1em;
}

.modal-cancel-plan-title,
.swal2-title {
  font-size: 24px;
}

.swal2-html-container {
  font-size: 16px !important;
}

.modal-cancel-plan-button,
.swal2-confirm,
.swal2-cancel {
  font-size: 14px !important;
}

.modal-refund {
  .user__detail-group-list-heading {
    font-size: 18px;
    color: #f6660c;
    font-weight: 600;
  }

  tbody {
    display: block;
    overflow: auto;
    height: 200px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }

  // thead {
  //   width: calc(100% - 1em); /* scrollbar is average 1em/16px width, remove it from thead width */
  // }

  th[scope='col'] {
    font-size: 16px;
    color: #0badad;
  }

  th {
    font-size: 14px;
  }

  td {
    font-size: 14px;
    // &:last-child {
    //   padding-left: 0px !important;
    // }
  }
}

@media screen and (max-width: 768px) {
  .swal2-title {
    font-size: 26px;
  }
  .swal2-html-container {
    font-size: 16px;
  }

  button.swal2-styled {
    font-size: 14px !important;
  }

  .invoice-item {
    font-size: 0.725em;

    .btn-primary,
    .btn-cancel-plan {
      height: 30px;
    }
  }

  th {
    font-size: 11px !important;
  }

  td {
    font-size: 10px !important;
  }
}
