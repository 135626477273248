@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

.btn ~ .btn {
  margin-top: 0;
}

.bg-theme-primary {
  background-color: $colormain !important;
}

// .css-b62m3t-container {
//   min-width: 400px;
// }

.css-1s2u09g-control {
  border-radius: 0 !important;
}

.css-14el2xx-placeholder {
  color: $orange !important;
}

#manage-order-page {
  font-size: 1vw;

  a {
    color: $gray-900;
  }

  h3 {
    color: $orange;
  }

  .col-auto {
    flex: 1;
  }

  .btn-export {
    border-color: $orange;
    color: $orange;
    width: 140px;
    border-radius: 999px !important;

    &:hover {
      background-color: $orange;
      color: white;
    }
  }

  .form-select {
    padding: 8px 1.75rem 8px 12px;
    font-size: 1vw;
    width: 140px;
    color: $orange;
    font-weight: 500;
  }

  .manage-orders {
    font-size: 1vw;
  }

  .c-form--search .form-group .form-control {
    padding-left: 35px !important;
  }

  .btn-clear-filter,
  .btn-date,
  .btn-prev,
  .btn-next {
    background-color: #fff;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    color: $orange;
    font-weight: 500;
    width: auto;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: $orange;
      color: #fff;
      border: 1px solid $orange !important;
    }
  }

  .list-event-item {
    border: none;
  }

  .list-event {
    .list-event__item {
      font-size: 1vw;
      .col {
        overflow-wrap: anywhere;
      }
    }
  }
}

.list-event__item .icon-new {
  position: inherit;
}

.btn-cancel-calendar {
  outline: none;
  border: none;
  font-size: 0.625rem;
}

.form-select:focus {
  box-shadow: none;
  border-color: $orange;
}

// .rdrNextButton {
//   margin-right: 0;
// }

// .rdrPprevButton {
//   margin-left: 0;
// }

.rdrMonth {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #15cdca;
}
