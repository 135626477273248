$secondary-color: #ff6903;
$gray-color: #8c8c8c;
$disabled-color: #e4e4e4;

.my-navigation {
  .page-item {
    &.active {
      .page-link {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: white;
      }
    }

    &.disabled {
      .page-link {
        background-color: $disabled-color;
        border-color: $disabled-color;
        cursor: not-allowed;
      }
    }

    .page-link {
      color: $gray-color;
      font-size: 10px;

      &:active,
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
