@import './creditCard.scss';

$color-primary: #708090;
$color-link: #fd7e14;

.card-info {
  a {
    font-size: 0.8rem;
    color: $color-link;
    &:hover {
      color: darken($color-link, 10%);
    }
  }

  .title {
    font-size: 20px;
  }

  .info-card-payment {
    font-size: 16px;

    .form-control:focus {
      outline: none;
      box-shadow: none !important;
      border-color: $color-primary !important;
    }
  }

  .current-plan {
    border-width: 2px !important;
  }

  .btn-update {
    background-color: $color-primary !important;
    color: white;
    border-color: $color-primary !important;
    &:active {
      border-color: darken($color-primary, 10%) !important;
      background-color: darken($color-primary, 10%) !important;
      color: white;
    }
  }
}
