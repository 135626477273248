$color-primary: #15cdca;
$color-highlight: #002b5c;

.modal-hit-pay-payment {
  input {
    display: none;
  }

  .highlight {
    color: $color-highlight;
  }

  .title-service {
    font-size: 24px;
  }

  .border-primary {
    border-color: $color-primary !important;
  }

  .payment-notice-container {
    width: 18rem; /* Adjust to match the card widths if necessary */
    margin: auto; /* Center the container in the modal */
    text-align: center; /* Ensure the text within is centered */
  }

  .payment-notice {
    font-size: 16px;
    font-weight: 600; /* Medium to bold for emphasis */
    color: #0056b3; /* A professional, trust-inspiring color */
    text-align: center; /* Center align the text for balance */
    margin: 0; /* Add some space above and below the text */
  }

  .btn-primary:hover {
    background-color: darken($color-primary, 10%) !important;
    border-color: darken($color-primary, 10%) !important;
    transition: all 0.2s ease-in-out;
  }

  .card {
    &:hover {
      border-color: $color-primary !important;
      transition: all 0.3s;
    }

    .card-title {
      font-size: 18px;
    }

    .card-subtitle {
      font-weight: 600;
      color: $color-highlight;
    }

    .card-text {
      font-size: 14px;
    }

    button:disabled {
      cursor: not-allowed !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal-hit-pay-payment {
    .title-service {
      font-size: 22px;
    }

    .payment-notice {
      font-size: 14px;
    }
  }
}
