// @import 'functions';
// @import 'variables';
// @import 'mixins';

.event-page {
  b {
    color: #51aaaa;
  }
  .c-form--search {
    margin-top: 0;
  }
}

