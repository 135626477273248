/* @media screen and (min-width: 768px) {
  html,
  body {
    font-size: 16px;
  }
} */

:root {
  --color-primary: #15cdcd;
  --color-highlight: #525252;
  --color-gray: #797979;
  --color-solid: #07c3c3;
  --color-orange: #ff6903;
  --color-solid: #0badad;
  --color-solid-hover: #0badad;
  --color-solid-focus: #0badad;
}

.skeleton {
  background: #e0e0e0;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* .form-control:focus {
  border: 1px solid var(--color-primary) !important;
} */

/* .btn--solid:focus {
  background: var(--color-solid) !important;
  border: 1px solid var(--color-solid) !important;
  color: white !important;
} */

.btn--solid:hover {
  background: var(--color-solid-hover) !important;
  border: 1px solid var(--color-solid-hover) !important;
  color: white !important;
}

.btn--solid:disabled {
  background-color: var(--color-gray) !important;
  border: 1px solid var(--color-gray) !important;
  color: white !important;
}

#comment2:focus {
  border: 1px solid var(--color-primary);
}

/* .form-control:focus {
  box-shadow: none;
} */

.f-12 {
  font-size: 12px; /* Extra Small */
}

.f-14 {
  font-size: 14px; /* Small */
}

.f-16 {
  font-size: 16px; /* Medium */
}

.f-18 {
  font-size: 18px; /* Large */
}

.f-20 {
  font-size: 20px; /* Extra Large */
}

.f-24 {
  font-size: 24px; /* Double Extra Large */
}

.f-28 {
  font-size: 28px; /* Title Size */
}

.f-32 {
  font-size: 32px; /* Heading Size */
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: var(--color-highlight) !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: var(--color-highlight) !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: var(--color-highlight) !important;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: var(--color-highlight) !important;
}

.tooltip-event-item .tooltip-inner {
  background-color: white;
  color: var(--color-highlight);
  border: 1px solid var(--color-highlight);
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px !important;
  width: 100%;
}

.react-joyride__overlay {
  position: fixed !important;
}

.text-525252 {
  color: var(--color-highlight) !important;
}

.text--primary {
  color: var(--color-primary) !important;
}

.custom-scrollbar {
  overflow: hidden scroll;
}

/* total width */
.custom-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* HTML: <div class="loader"></div> */
.loader {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 20px;
  animation: loadingAnimate 1s linear infinite alternate;
  text-align: center;
  margin: 200px auto auto auto;
  color: #51aaaa;
}

.loader:before {
  content: 'Loading...';
}

@keyframes loadingAnimate {
  to {
    opacity: 0;
  }
}

.__floater__body button,
.__floater__body button:active,
.__floater__body button:focus {
  outline: none;
}

.fc .fc-daygrid-day-top {
  cursor: pointer;
}

.btn--org:disabled {
  background-color: var(--color-gray) !important;
  border: 1px solid var(--color-gray) !important;
  color: white !important;
}

.btn--org:active {
  background-color: var(--color-orange) !important;
  border: 1px solid var(--color-orange) !important;
  color: white !important;
}

.btn--solid:active {
  background-color: var(--color-solid) !important;
  border: 1px solid var(--color-solid) !important;
  color: white !important;
}

.btn--main:disabled {
  background-color: var(--color-gray) !important;
  border: 1px solid var(--color-gray) !important;
  color: white !important;
}

.btn--main:active {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  color: white !important;
}

.btn--gray:focus {
  background-color: var(--color-gray);
  border: 1px solid var(--color-gray);
  color: white;
}

.btn.disabled {
  background: var(--color-gray) !important;
  border-color: var(--color-gray) !important;
  color: white !important;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-check-input:checked {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
}

.text-blue {
  color: #51aaaa !important;
}

button.swal2-styled:focus,
button.swal2-styled:active {
  box-shadow: none !important;
}

/* button.swal2-styled {
  background-color: #15cdca !important;
} */

/* button.swal2-cancel {
  background-color: #797979 !important;
} */
