@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

.my-events-page {
  font-family: $font-family-other;
  .group-list {
    margin-top: 0;
    &__item {
      p {
        cursor: pointer;
      }
    }
  }
  .nav-tab {
    margin-top: 8px;
    .action {
      &:hover {
        color: white !important;
      }
      cursor: pointer;
    }
  }
}
.scheduled-event {
  background: #fff4ed !important;
}
.scheduled-event-text-right {
  background: #079d9a;
  border-radius: 5px;
  color: white;
  font-size: 0.775rem;
  padding: 2px 2px;
  text-align: center;
}
.scheduled-event-right {
  flex: 0 0 200px !important;
  max-width: 200px !important;
  @media (max-width: 767px) {
    flex: 0 0 180px !important;
    max-width: 180px !important;
  }
}
.hosted__event-list-desktop {
  width: 1000px !important;
  &-title {
    margin-bottom: 0;
    font-weight: bold;
    color: $orange;
  }
  &-item-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 1%;
    .group-list--event {
      width: 100% !important;
      // margin-top: 0px;
      &:nth-child(2) {
        margin-top: 0px;
      }
    }
    .event-item-desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.event-desktop-page {
  overflow: hidden scroll;
  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}
