.dashboard__desktop {
  // width: 1000px;
  margin: auto;
  overflow-y: scroll;
  overflow-x: hidden;

  /* total width */
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }

  &-head {
    // margin: 20px -4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      margin-bottom: 0;
      color: #ff6903;
    }
    &-sync {
      display: flex;
      align-items: center;
      gap: 16px;

      &-date {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-size: 0.9rem;
        background: #ffffff;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        }

        &-start, &-end {
          color: #414141;
          font-weight: 500;
        }

        &-start {
          margin-right: 8px;
        }

        &-end {
          margin-left: 8px;
        }
      }

      &-week {
        margin-left: 0;
        .form-select {
          min-width: 120px;
          padding: 8px 16px;
          border-radius: 8px;
          border: 1px solid #e0e0e0;
          color: #414141;
          background-color: #ffffff;
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            border-color: #ff6903;
          }

          &:focus {
            border-color: #ff6903;
            box-shadow: 0 0 0 2px rgba(255, 105, 3, 0.1);
          }
        }
      }
    }
  }

  .summary {
    .border-custom {
      border: 1px solid #15cdca;
      font-weight: 600;

      span {
        color: #414141;
      }
      .text-show {
        color: #ff6903;
      }
    }
  }

  .btn-prev,
  .btn-next {
    background-color: #fff;
    padding: 8px 12px;
    border: 1px solid #ced4da;
    color: #ff6903;
    font-weight: 500;
    width: auto;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: #ff6903;
      color: #fff;
    }
  }
}
.chart-layout {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  margin-top: 20px;
  > div {
    padding: 12px;
    border-radius: 16px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
    > p {
      margin-bottom: 8px;
      color: black;
      font-weight: 600;
    }
  }
}
.datePicker {
  &__startDay,
  &__endDay {
    margin-bottom: 24px;
  }
}
.sync-data-btn {
  > button {
    width: 320px;
    margin: 0 auto;
  }
}
.top-members_list {
  margin-bottom: 0;
  li {
    display: flex;
    list-style: none;
    justify-content: space-between;
    font-size: 0.9rem;
    color: #414141;
  }
}
.top-members {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.modal-sync-data {
  .react-datepicker-wrapper {
    min-width: 100%;
    width: 100%;
  }
}
