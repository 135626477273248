@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

.update-group-page {
  font-family: $font-family-other;
  width: 100%;

  .box-setting {
    background-color: #f0f0f0;
    border: 1px solid gray;
    .custom-control-label {
      font-size: 0.65rem !important;
    }
  }

  .custom-radio {
    border: 1px solid #248e8e;
    background-color: #d9d9d9;
    position: relative;
    margin-bottom: 0;
    height: 100%;

    &.active {
      background-color: #e6f9f9;
      border: 1px solid #248e8e;
    }
    p {
      font-size: 12px;
      margin-bottom: 0;
      color: black;
    }
    input[type='radio'] {
      cursor: pointer;
    }
  }
  .custom-radio input[type='radio'] + label:before {
    width: 15px;
    height: 15px;
    top: 14px;
    border: 0.1em solid #248e8e;
    background-position: center !important;
  }

  .custom-radio input[type='radio']:checked + label:before {
    background-size: 8px;
  }

  .c-form .form-group label {
    font-size: $font-size-sm;
  }
  .form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }
  .form-group {
    .c-txt-blue {
      font-size: $font-size-sm;
    }
  }
  .box-setting {
    .custom-control-label {
      font-size: 0.75rem !important;
    }
  }
  #groupImageImg {
    z-index: 999;
  }
}

.errorMessage {
  color: #ff9900;
}
