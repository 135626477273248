.w-max-inherit {
  max-width: inherit;
}

.custom-scroll {
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
    width: 10px;
    background-color: #f5f5f5;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    background-color: #bcc0c4;
  }
}

.animate {
  transition: all 0.3s ease-in-out;
}

.menu-shrink {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;

  .wrap-calendar {
    display: none;
  }
}

.menu-dilate {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;

  .wrap-calendar {
    display: flex;
    overflow: auto scroll;
    margin-right: 3px;
    height: calc(100% - 400px);

    /* total width */
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
