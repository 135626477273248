#modal-placed {
  &.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .container {
    justify-content: center;
  }
}

.manageBtn {
  display: inline-block;
  color: grey;
  font-size: 12px;
}

.detail-list-news__item h3 {
  color: #51aaaa;
}

.row-top .group-list--event .group-list__detail .col-3 {
  font-weight: normal;
}

.quanlity {
  .icon-mim,
  .icon-plugs {
    min-width: 26px;
  }
}

.termCond {
  .custom-control {
    display: flex;
  }
  .c-txt-12 {
    cursor: pointer;
  }
}

.extraFields {
  margin: 15px 0;
  .custom-control-label {
    font-size: 1rem;
  }
}

.soldOut {
  font-size: 0.75rem;
  font-weight: 600;
  color: #ff1919;
}
#alert-event-in-private-group {
  margin: 46px auto;
}

.event-details-page {
  .form-control:focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
