$secondary-color: #ff6903;

.get-shop-opening {
  b {
    color: #51aaaa;
  }
  .c-form--search {
    margin-top: 0;
  }
  .title {
    color: $secondary-color;
  }
  .select-status {
    font-size: 16px;
    color: $secondary-color;
    width: 120px;
    &:focus {
      outline: none;
      border: 1px solid $secondary-color !important;
    }
  }
  .page-item.active {
    .page-link {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
  .page-link {
    color: #51aaaa;
    &:focus {
      box-shadow: none;
    }
  }
  .image-gallery-image {
    object-fit: cover;
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
  }
  .pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: center;
    .pagination-item {
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.3);
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
      transition: all 0.2s ease-in-out;
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
      &.selected {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: white;
      }
      &.disabled {
        pointer-events: none;
        color: gray;
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
}
.shop-item {
  border-radius: 15px;
  border: 1px solid rgba(255, 105, 3, 0);
  transition: linear 0.3s;
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */

  &:hover {
    border: 1px solid $secondary-color;
  }

  a {
    margin-right: 1rem;
    color: #7c797a;
    transition: all 0.2s ease-in-out;
  }
  img {
    object-fit: cover;
    width: 100%;
  }
  .carousel-item,
  .wrap-banner {
    img {
      height: 250px;
    }
    button {
      display: none;
    }
  }
  .link-icons {
    font-size: 25px;
  }

  .shop-name {
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      color: black;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .shop-description {
    overflow-wrap: anywhere;
    margin-bottom: 1rem;
  }
  .shop-content {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  #digistall {
    .shop-name {
      font-size: 16px !important;
    }

    .shop-description {
      font-size: 14px !important;
    }

    .shop-item {
      .carousel-item,
      .wrap-banner {
        img {
          height: 125px;
        }
      }

      .link-icons {
        font-size: 16px;
      }
    }
  }
}
