@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

$button-color: #51aaaa;
$secondary-color: #f06a0f;

.list-events-page {
  font-family: $font-family-other;

  .c-txt-14 {
    color: $secondary-color !important;
  }
  .link-share,
  .total-members {
    cursor: pointer;
  }
  .host-an-event {
    cursor: pointer;
  }
  .badge-light-link {
    color: #007bff !important;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: #51aaaa !important;
      text-decoration: none !important;
    }
  }
}

.modal-subscription-success {
  transform: translateY(50%) !important;
  .modal-content {
    overflow: hidden;
    padding-top: 0 !important;
  }

  .btn-submit {
    background: $button-color;
    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
      background-color: $button-color;
    }
  }
}
