.payment-group {
  .row {
    font-size: 16px;
  }

  .price {
    font-size: 20px;
  }
  .payment-active {
    border: 1px solid #15cdca;
  }
  .form-check {
    height: 120px;

    .form-check-label {
      text-align: center;
    }
  }
}
