@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

//Color Variables
$sidebar-background-color: #313443;
$active-sidebar-link-color: #22252e;
$hover-sidebar-link-color: $active-sidebar-link-color;
$active-link-color: #98d7ec;
$tooltip-background-color: $sidebar-background-color;

.sidebar {
  font-size: 14px;
  // color: #1ca5a2;
  min-width: 250px;
  max-width: 250px;
  width: 250px;
  transition: all 0.3s ease-in-out;

  &.collapsed {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
  }

  .d-flex {
    max-width: 100%;
    flex-grow: 0;
    overflow: hidden;
  }

  .flex-column {
    max-width: 100%;
  }

  img {
    width: 2rem;
    height: 2rem;
  }

  a {
    color: #1ca5a2;
    &:hover {
      transition: all 0.2s ease-in-out;
    }
  }

  span:hover {
    opacity: 0.7;
  }

  .form-select {
    font-size: 0.75vw;
    padding: 0.375rem 1rem 0.375rem 0.5rem;
    background-position: right 0.25rem center;

    option {
      background-color: white;
      white-space: normal;
    }

    &:focus {
      border: 1px solid $primary;
    }
  }

  .pl-15 {
    padding-left: 15px !important;
    transition: all 0.3s ease-in-out;
  }

  .list-menu {
    display: inline-block;

    .menu-item {
      cursor: pointer;
      transition: all ease-out 120ms;

      a {
        font-size: 10px;
      }

      .icons {
        font-size: 16px;
        transition: all ease 450ms;
      }

      &.active {
        background-color: black;
      }

      .tooltip {
        display: inline-block;
        position: absolute;
        background-color: #fd7e14;
        padding: 8px 15px;
        border-radius: 3px;
        margin-top: -3px;
        left: 65px;
        opacity: 0;
        visibility: hidden;
        font-size: 13px;
        width: max-content;
        letter-spacing: 0.5px;
        color: white !important;
        z-index: 999;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -4px;
          top: 12px;
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: inherit;
        }
      }

      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .style-menu {
    border-bottom: 25px solid transparent;
    border-right: 25px solid #f2f0f0;
    position: relative;
    cursor: pointer;
    z-index: 10;
    max-width: 100%;
    flex-shrink: 0;

    .group span {
      color: #707070;
    }

    span:nth-child(1) {
      position: absolute;
      top: 20px;
      right: -35px;
      display: inline-block;
      transform: rotate(-90deg);
    }

    span:nth-child(2) {
      position: absolute;
      top: 60px;
      font-size: 18px;
      left: 5px;
    }
  }

  .w-fit {
    width: fit-content !important;
  }

  .select-box {
    position: relative;
    user-select: none;

    .selected {
      font-size: 14px;
      padding: 8px 10px;
      height: 32px;
      border: 1px solid #ccc;
      border-radius: 6px;
      background-color: white;
      color: gray;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .loading-skeleton {
      background: #f0f0f0;
      animation: pulse 1.5s infinite;

      .skeleton-text {
        height: 14px;
        width: 80%;
        background: #e0e0e0;
        border-radius: 4px;
      }
    }

    .options {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      font-size: 1vw;
      border-radius: 6px;
      background-color: white;
      max-height: 150px;
      z-index: 10;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: opacity 0.3s ease, transform 0.3s ease;
      color: gray;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      &.open {
        opacity: 1;
        transform: translateY(3px);
      }

      .option {
        padding: 5px 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 32px;

        &:hover {
          background-color: #f0f0f0;
        }

        .truncate-text {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

.text-orange {
  color: $orange !important;
}

@media screen and (max-width: 1204px) {
  .sidebar {
    .selected {
      font-size: 12px;
    }
  }
}
