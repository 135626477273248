@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

.event-page {
  font-family: $font-family-other;
}

.group-list {
  margin-top: 50px;

  &__item {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba($color: #707070, $alpha: 0.3);
    padding-top: 20px;
    padding-bottom: 30px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
    }

    .overlap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 6;
    }

    &--pending {
      * {
        color: #8f8c8d !important;
      }
    }
  }

  &__img {
    flex: 0 0 60px;
    max-width: 60px;
    padding-right: 15px;
  }

  &__info {
    // color: #51AAAA;
    flex: 1;
    word-break: break-word;

    h3 {
      font-size: rem(12);
      font-weight: bold;
      margin: 0;

      a {
        color: inherit;
      }
    }

    p {
      margin: 0;
      font-size: rem(11);

      a {
        color: inherit;
      }

      span {
        font-size: rem(9);
        display: inline-block;
        padding-right: 10px;
      }
    }
  }

  &__btn {
    flex: 0 0 200px;
    max-width: 200px;
    align-self: flex-start;

    @include media-breakpoint-down(sm) {
      flex: 0 0 150px;
      max-width: 150px;
    }
  }

  &--event {
    // margin-top: 0px;
    width: 100%;

    .txt-date {
      font-size: rem(11);
      display: block;
      // color: #7C797A;
      color: #f06a0f;
    }

    .txt-number {
      font-size: rem(11);
      background: #f06a0f;
      color: #fff;
      padding: 3px 11px;
      border-radius: 5px;
      display: inline-block;
    }

    h4,
    .delivery-info {
      color: #51aaaa;
      font-size: rem(14);
      font-weight: bold;

      a {
        color: inherit;
      }

      span {
        font-weight: normal;
      }
    }

    .group-list {
      &__item {
        border: none;
        flex-wrap: wrap;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        // background-color: #fff;
        border-radius: 15px;
        margin-bottom: 1rem;

        .text-right {
          font-size: 0.7rem;
          color: rgb(7, 157, 154);
          // line-height: 1;
        }

        &--nobackground {
          box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16);
          background-color: #fff;
          border-radius: 0px;
        }

        &:first-child {
          @include media-breakpoint-down(sm) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        &.is-closed {
          background-color: #f2f1f1;
          color: #7c797a;
          opacity: 0.5;

          h3,
          h4,
          svg,
          .text-right,
          .txt-date {
            color: #7c797a !important;
          }

          .btn-purchase {
            color: #e4e4e4 !important;

            &.txt-number {
              color: white !important;
            }
          }
        }

        .link-share {
          // position: absolute;
          right: 26px;
          bottom: 10px;
          z-index: 7;
        }
      }

      &__des {
        flex: 0 0 100%;
        max-width: 100%;
        // padding-left: 60px;
        margin-top: 10px;
        font-size: rem(16);

        .paragraph-pre-wrap button {
          text-align: left;
          color: gray;
        }

        a.btn-purchase:hover {
          color: #fff !important;
        }

        figure {
          text-align: center;

          img {
            // width: 100%;
            width: auto;
            height: 250px;
          }
        }

        p {
          // line-height: (24/12);
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // max-height: 60px;
          margin: 0;
          font-size: rem(15);
          text-align: justify;
          word-break: break-word;
          margin-bottom: 10px;

          a {
            color: inherit;
          }

          @include media-breakpoint-down(md) {
            // max-height: 48px;
          }
        }
      }

      &__detail {
        font-size: rem(15);
        // padding-left: 60px;
        margin-top: 10px;
        width: 100%;

        .row {
          & + .row {
            margin-top: 5px;
          }
        }

        p {
          margin: 0;
          font-size: rem(15);
        }

        .col-3 {
          font-weight: bold;
          font-size: rem(14);
          flex: 0 0 30%;
          max-width: 30%;
        }

        .col-9 {
          flex: 0 0 70%;
          max-width: 70%;
        }

        .text-blue {
          font-size: rem(11);
          color: #51aaaa;
        }

        .row {
          & ~ .row {
            // margin-top: 10px
          }
        }
      }
    }

    &.group-list--event02 {
      .group-list__info {
        color: #7c797a;
      }

      .txt-date {
        color: #f06a0f;
      }

      .txt-number {
        background: #f06a0f;
        color: #fff;
        padding: 3px 11px;
        border-radius: 5px;
        display: inline-block;
      }

      .group-list__des {
        padding-left: 0;
        overflow-wrap: break-word;

        figure {
          margin-left: -20px;
          margin-right: -20px;
        }

        h4 {
          margin-top: 10px;
          color: #51aaaa;
        }

        .image-gallery-slide img {
          width: auto;

          @media (max-width: 767px) {
            height: 280px !important;
          }

          @media (min-width: 768px) {
            height: 320px !important;
          }

          @media (min-width: 992px) {
            height: 380px !important;
          }
        }
      }

      .group-list__detail {
        padding-left: 0;
      }
    }

    &.group-list--event01 {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 15px;
      padding: 10px;
      margin-left: -15px;
      margin-right: -15px;
      width: auto;

      p {
        line-height: (24/12);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: (24/12) * 3rem;
      }

      & ~ .group-list--event01 {
        margin-top: 20px;
      }

      .group-list__item {
        box-shadow: 0 3px 6px rgb(0 0 0 / 0%);
        margin-left: 0;
        margin-right: 0;
        padding: 0;
      }

      .btn--solid.btn--solid03 {
        margin-top: 20px;
        margin-bottom: 20px;
        color: #656565;
        border: 1px solid #ababab;
      }

      .group-list__info p {
        a {
          text-decoration: underline;
        }
      }

      .price {
        font-size: 10px;
        font-weight: normal;
        margin: 4px 0 0 0;
      }
    }
  }

  &--mygroup {
    margin-top: 0;
    margin-bottom: 30px;

    h3 {
      font-size: rem(14);
      color: #f06a0f;
    }

    .group-list__item {
      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
      }
    }

    .group-list__info {
      p {
        span {
          font-size: rem(12);

          &.t-member {
            color: #da5039;
          }

          &.t-deal {
            color: #51aaaa;
          }
        }
      }
    }

    .group-list__btn {
      flex: 0 0 40px;
      max-width: 40px;
    }
  }
}

.event-item {
  .nav-tab li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    color: #7e7e7e;
    font-weight: bold;
    font-size: 0.625rem;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    margin: 0 10px;
    cursor: pointer;
  }
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  padding-right: 0 !important;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  color: #495057 !important;
  -moz-appearance: textfield;

  &:focus-visible {
    border: none !important;
  }
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar {
  width: 300px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: $font-family-other !important;
  line-height: 1em;
  font-size: 12px !important;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: transparent !important;
  color: inherit !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

input[type='time'] {
  color: #495057;

  &:focus {
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    filter: opacity(0.5);
  }
}

/* form delivery start*/
.icon-truct {
  margin-left: 2.2rem;
  font-size: 3rem;
  color: rgb(7, 157, 154);
  line-height: 0;
  z-index: 1;
  margin-bottom: -7px;
}

.delivery-form {
  background: #079d9a;
  padding: 0 1rem 1rem 1rem;
  border-radius: 1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    .header-left {
      line-height: 1.2;
      display: flex;
      flex-direction: column;
      color: white;

      &--text {
        font-size: 1.65rem;
        font-weight: 700;
      }

      &--date {
        font-size: 1rem;
      }
    }

    .header-right {
      display: flex;
      line-height: 1.2;
      color: white;
      font-size: 1rem;

      &--text {
        display: flex;
        flex-direction: column;
        margin-right: 0.1rem;
        align-content: space-between;
      }

      &--img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background: #fff;
        object-fit: cover;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .form-input {
    margin-bottom: 1rem;

    label {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 700;
      margin-bottom: 0 !important;
      color: white;
    }
  }

  .form-input-size {
    display: block;
    width: 100%;
    height: 2rem;
    padding: 0.3rem 0.4rem;
    border-radius: 0.2rem;
    outline: 0;
    border-style: none;
    margin-top: 0.4rem;
    background-color: white;
  }

  .button-submit {
    text-align: right;

    button {
      background-color: #15cdca;
      color: white;
      font-size: 0.75rem;
      border-radius: 1.5rem;
      outline: none;
      border-style: none;
      padding: 0.5rem 3rem;
    }
  }
}

/* form delivery end*/

/* list check box purchase detail*/
.list-check-box-container {
  border: none;
  flex-wrap: wrap;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 15px;
  margin-top: 5px;
  padding: 20px;
}

.truncate {
  position: absolute;
  top: -18px;
  left: 10px;
  background-color: white;
  padding: 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 380px) {
    max-width: 230px;
  }

  @media (min-width: 381px) {
    max-width: 300px;
  }

  @media (min-width: 768px) {
    max-width: 400px;
  }
}

.text-color-blue {
  color: rgb(7, 157, 154);
  font-size: 1.1rem !important;
  line-height: 1;
  cursor: pointer;
  border: 2px solid;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
}

.text-add-delivery {
  line-height: 1rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  color: rgb(7, 157, 154);
}

.box-same-delivery-current-order {
  background: rgba(33, 205, 202, 0.09);
  color: #939091;
  padding: 20px;
  border-radius: 15px;
  font-size: 0.6875rem;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin-bottom: 20px;

  &__item-order {
    margin-bottom: 10px;
  }
}

.list-check-box-container {
  border: none;
  flex-wrap: wrap;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 15px;
  margin-top: 5px;
  padding: 15px;

  .box-order-with-delivery {
    border: 1px solid #51aaaa;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;

    &__item-order {
      margin-bottom: 4px;
    }
  }
}

.text-cancel-delivery {
  margin-top: 10px;
  font-size: 0.6rem;
  font-weight: bold;
  color: rgb(7, 157, 154);
  text-decoration: underline;
  cursor: pointer;
}

.input-delivery-date {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px;

  .form-control {
    border: none !important;
  }
}

.group-hour-delivery {
  display: flex;
  justify-content: space-between;

  @media (max-width: 340px) {
    flex-direction: column;

    .input-delivery-hour {
      margin-top: 0.2rem;

      input {
        width: 100%;
      }
    }

    .icon-cross {
      display: none;
    }
  }

  .input-delivery-hour {
    div {
      font-size: 0.775rem;
    }

    input {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 10px;
      padding: 0.375rem 0.75rem !important;
      border: none !important;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-position: 90% center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='18' height='18'%0AviewBox='0 0 172 172'%0Astyle=' fill:%23000000;'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'%3E%3C/path%3E%3Cg fill='%23495057'%3E%3Cpath d='M86,14.33333c-39.49552,0 -71.66667,32.17115 -71.66667,71.66667c0,39.49552 32.17115,71.66667 71.66667,71.66667c39.49552,0 71.66667,-32.17115 71.66667,-71.66667c0,-39.49552 -32.17115,-71.66667 -71.66667,-71.66667zM86,28.66667c31.74921,0 57.33333,25.58412 57.33333,57.33333c0,31.74921 -25.58412,57.33333 -57.33333,57.33333c-31.74921,0 -57.33333,-25.58412 -57.33333,-57.33333c0,-31.74921 25.58412,-57.33333 57.33333,-57.33333zM78.83333,43v45.96744l30.76628,30.76628l10.13411,-10.13411l-26.56706,-26.56706v-40.03256z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 10px;
      padding: 0.375rem 2.3rem 0.375rem 0.75rem !important;
      border: none !important;
      color: #495057;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}

.input-delivery-group {
  select {
    color: #495057;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    padding: 0.375rem 0.75rem !important;
    border: none !important;
    width: 100%;

    &:focus {
      border: none !important;
      outline: none;
    }
  }
}

.pay-together {
  font-style: italic;
  font-size: 0.675;
}

.cursor-pointer {
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-item {
  border: 1px solid;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;

  &.selected {
    background-color: #51aaaa;
    color: #fff;
    border: 1px solid #51aaaa;

    &:hover {
      background-color: #51aaaa;
      color: #fff;
      border: 1px solid #51aaaa;
      transition: all 0.3s ease-in-out;
    }
  }

  &.disabled {
    background-color: lightgray;
    color: white;
    border: 1px solid lightgray;
    cursor: not-allowed;
  }

  &:hover {
    border: 1px solid #51aaaa;
    color: #51aaaa;
    transition: all 0.3s ease-in-out;
  }
}

.modal-event {
  .btn-outline-primary {
    color: #0badad;
    border-color: #0badad;

    &:hover {
      background-color: #0badad;
      border-color: #0badad;
      color: white;
    }

    &:active {
      background-color: darken(#0badad, 10%) !important;
      border-color: darken(#0badad, 10%) !important;
      color: white;
    }
  }

  .btn-primary {
    &:hover {
      background-color: darken(#0badad, 5%) !important;
      border-color: darken(#0badad, 5%) !important;
    }
    &:active {
      background-color: darken(#0badad, 10%) !important;
      border-color: darken(#0badad, 10%) !important;
    }
  }
}
