#manage-order-page {
  font-family: 'Oxygen', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif',
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  .btn--solid03 {
    &:hover {
      color: white !important;
    }
  }
}
.nav-tab li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
  color: #7e7e7e;
  font-weight: bold;
  font-size: 0.625rem;
  border-radius: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  margin: 0 3px;
  padding: 14px 0;
  &:hover {
    background-color: aqua;
    color: white;
  }
}
.modal-header .modal-title {
  text-align: center !important;
  color: #ff6903;
}
.form-control-payment {
  display: block;
  width: 100%;
  line-height: 1.5;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  height: 25px !important;
  border-radius: 0px;
  font-size: 0.7rem;
  position: relative;
  text-align: center;
  border: none;
  white-space: nowrap;

  &:active,
  &:focus {
    outline: none;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
  }
}

.modal-header .modal-title {
  width: 100%;
}
.detail-list-news__item {
  margin-left: 0;
  margin-right: 0;
  color: #ff6903;
}
.nav-tab li:hover {
  background-color: #07c3c3;
  cursor: pointer;
}
.list-event-note ~ .list-event .list-event__item:first-child {
  border-top: none;
}
.modal-broadcast {
  .form-control:focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  }

  .btn--org:focus {
    background-color: #ff6903 !important;
    border: 1px solid #ff6903 !important;
    color: white !important;
  }
}
